import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"

import Meta from "../../components/addon/meta"
import Layout from "../../components/layout/layout"
import APIButton from "../../components/addon/button/apiButton"
import LinkButton from "../../components/addon/button/linkButton"

import useScript from "../../service/useScript"
import { acceptOnlyNumbers, getCookie } from "../../service/helper"
import USER_API from "../../api/user"
import DOCTOR_API from "../../api/doctors"
import { handleHHLogin } from '../../service/auth'

const MedicalReports = (props) => {
    const [detailsType, setDetailsType] = useState('hh_no')
    const [hhNumber, setHHNumber] = useState('')
    const [exno, setExNo] = useState('')
	const [orderNo, setOrderNo] = useState('')
    const [mobile, setMobile] = useState('')
    const [otp, setOTP] = useState('')
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [otpLoading, setOTPLoading] = useState(false)
    const [loading, setLoading] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)
    const [success, setSuccess] = useState(false)
	const [step2, setStep2] = useState(null)
    const [data, setData] = useState(null)
    const [, forceUpdateForRegister] = useState();
	
	const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatag ? props?.data.allNodeTemplatePages.edges[0].node.metatag : []

    useScript("/assets/js/login-register.js");
    useScript("/assets/js/confirmpay.js")

    const [hhValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const [exNoValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const [orderNoValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >The OTP field is required.</p>}
    )))

    const handleRadioBtn = (e) => {
        const { id } = e?.target
        setDetailsType(id)
		resetData()
    }

    const resetData = () => {
        setSuccess(false)
        setHHNumber('')
        setExNo('')
        setOrderNo('')
		setData(null)
		setMobile('')
		setOTP('')
		setStep2(null)
		hhValidator.current.hideMessages()
		exNoValidator.current.hideMessages()
		orderNoValidator.current.hideMessages()
		otpValidator.current.hideMessages()
		forceUpdateForRegister(10)
    }

	const validateUserEXNumber = (exNumber) => {
        setLoading(true)
        USER_API.validateEXNumber(exNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber(exNumber)
            } else {
                toast.error(`Invalid EX Number`)
            }
			setLoading(false) 
        })
        .catch(err => {
            console.log(err)
            toast.error(`Invalid ${detailsType === 'hh_no' ? 'HS' : 'EX'} Number`)
            setLoading(false)
        })
    }

    const validateUserHHNumber = (hhNo) => {
        setLoading(true)
        USER_API.validateHHNumber(hhNo ? hhNo : hhNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber(hhNo)
            } else {
                toast.error(`Invalid ${detailsType === 'hh_no' ? 'HS' : 'EX'} Number`)
            }
			setLoading(false) 
        })
        .catch(err => {
            console.log(err)
            toast.error(`Invalid ${detailsType === 'hh_no' ? 'HS' : 'EX'} Number`)
            setLoading(false)
        })
    }

    const getDetailsHHNumber = async (hhNo) => {
        try {
            const result = await handleHHLogin(hhNo ? hhNo : hhNumber)
            if(result?.mobileno) {
				setMobile(result?.mobileno)
				setData(result)
				setStep2(true)
				setLoading(false)
				onResendOTP(result?.mobileno)
			} else {
				toast.error(`${detailsType === 'hh_no' ? 'HS' : 'EX'} Number not associated with mobile number`)
			}
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
            setLoading(false)
        }
    }

    const handleHHSubmit = (e) => {
        e.preventDefault();
        const hhResult = hhValidator.current.allValid()
        if(!hhResult) {
            hhValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(hhResult && hhNumber?.length) {
            validateUserHHNumber()
        }
    }

    const handleEXNoSubmit = (e) => {
        e.preventDefault();
        const exResult = exNoValidator.current.allValid()
        if(!exResult) {
            exNoValidator.current.showMessages()
            forceUpdateForRegister(2)
        }
        if(exResult && exno?.length) {
			validateUserEXNumber(exno)
        }
    }

	const handleOrderNoSubmit = (e) => {
		e.preventDefault();
        const orderResult = orderNoValidator.current.allValid()
        if(!orderResult) {
            orderNoValidator.current.showMessages()
            forceUpdateForRegister(3)
        }
        if(orderResult && orderNo?.length) {
			getPatientDetailsFromOrderNo()
        }
	}

	const handleMobileNoSubmit = () => {
		const otpResult = otpValidator.current.allValid()
        if (otpResult === false) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(4)
        } else {
            validateOTP()
        }
	}

	const handleMobileBack = () => {
		resetData()
	}

    const onResendOTP = (mobileNo) => {
        setOTPLoading(true)
        USER_API.sendOTP({ mobileNo : mobileNo ? mobileNo : mobile, "otpFor": "online_reports" })
        .then(response => {
            setVerifyOpen(true);
            toast.success("OTP sucessfully sent on mobile no.")
            setOTPLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong. Please try again later")
            setVerifyOpen(false);
            setOTPLoading(false)
        })
    }

    const validateOTP = () => {
        return USER_API.verifyOTP(otp)
        .then(res => {
			if(res) {
				setSuccess(true)
			} else {
				setSuccess(false)
				toast.error("Invalid OTP")
			}
            return res
        })
        .catch(err => {
            console.log(err)
			setSuccess(false)
            return false
        })
    }

	const getPatientDetailsFromOrderNo = () => {
		setBtnLoading(true)
		let payload = {
			patType:'HS',
			orderNumber: orderNo
		}
		DOCTOR_API.getPatientDetailsFromOrderNo(payload)
		.then(res => {
			if(res?.data) {
                setData(res.data)
				if(res?.data?.pin?.length) {
					setHHNumber(res?.data?.pin)
					validateUserHHNumber(res?.data?.pin) // res?.data?.pin
				} else {
					toast.error(res?.message ? res.message : 'Something went wrong')
				}
            } else if(res?.success === false) {
                toast.error(res?.message ? res.message : 'Something went wrong')
            }
			setBtnLoading(false)
		})
		.catch(err => {
			console.log(err)
			setBtnLoading(false)
		})
	}

	const goToDetails = () => {
		if(detailsType === 'exno') {
			navigate(`/medical-report/details`, { state: { regType:'EX', hhNumber: exno } }) //exno
		}else {
			navigate(`/medical-report/details`, { state: { regType:'HS', hhNumber: hhNumber } }) //hhNumber
		}
	}

	useEffect(() => {
		if(getCookie('hs')) {
			navigate(`/medical-report/details`, { state: { hhNumber: getCookie('hs') } }) //getCookie('hh')
		}
	},[getCookie('hs')])

	return (
	<>
		<Layout>
			<Meta
				files={{
					js: [],
					css: [
						'/assets/css/common.css', 
						"/assets/css/services.css", 
						"/assets/css/accessibility.css",
						'/assets/css/online-reports.css'
					],
				}}
				tags={metaTags}
			/>
			<main className="innerpage">
				{success 
                ? <section className="confirmation_section section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="tankyou_div">
                                <h2 className="section-heading">Confirmation</h2>
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g data-name="Group 2657" transform="translate(0)">
                                            <path data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"></path>
                                            <g data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                                <line data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Dear <span>{data?.fullname || ''},</span> HH no. {data?.pinNo || ''} </p>
                                    <p>Kindly confirm your name and HH No./EX No.</p>
                                    <p className="mt-4"> <span>If correct</span> - Please proceed</p>
                                    <p> <span>If incorrect</span> – Please go back and enter your correct details.</p>
                                </div>
                                <div className="btn_wrap btn_group">
                                    <button className="btn btn-primary btn-primary-solid back_btn" onClick={resetData}>Back</button>
                                    <a className="btn btn-primary proceed_btn" onClick={goToDetails}>Proceed</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
				: <section class="section_bg login_section pt-first-section">
					<div class="container">
						<h2 class="section-heading">Medical Reports</h2>
						<div class="row no-gutters">
							<div class="col-lg-12 col-md-12">
								<div class="onlineReportTopBoxWrap">
									<div class="white_rounded_box">
										<div class="form_wrap">
											<div class="pb-2 position-relative online-reports-heads">
												<h4>Please Select and Enter one of the below</h4> 
											</div>
											<div class="online-reports-form">
												{!step2
												? <div class="row">
													<div class="col-lg-12 col-12 py-3">
														<div class="form-group radio-group mb-0">
															<div class="row">
																<div class="col-md-4 col-6">
																	<div class="custom-control custom-radio">
																		<input checked={detailsType === 'hh_no'} onChange={handleRadioBtn} value="hh_no" type="radio" id="hh_no" />
																		<label htmlFor="hh_no">HH No</label>
																	</div>
																</div>
																<div class="col-md-4 col-6">
																	<div class="custom-control custom-radio">
																		<input checked={detailsType === 'exno'} onChange={handleRadioBtn} value="exno" type="radio" id="exno" />
																		<label for="exno">EX No.</label>
																	</div>
																</div>
																<div class="col-md-4 col-6">
																	<div class="custom-control custom-radio">
																		<input checked={detailsType === 'orderno'} onChange={handleRadioBtn} value="orderno" type="radio" id="orderno" />
																		<label for="orderno">Order No.</label>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div> 
												: null}
												<div class="row">
													{detailsType === 'hh_no' && <div className="col-lg-6 col-12">
														<div className={`form-group -animated ${step2 ? 'select-disabled -active' : ''}`}>   
															<label>Enter HH Number <span className="mandatory">*</span></label>
															<input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
															{hhValidator.current.message('HhNumber', hhNumber, 'required|min:3|max:10')}                                           
														</div>
													</div>}
													{detailsType === 'exno' && <div className="col-lg-6 col-12">
														<div className={`form-group -animated ${step2 ? 'select-disabled -active' : ''}`}>   
															<label>Enter EX No.<span className="mandatory">*</span></label>
															<input className="form-control" type="text" value={exno} onChange={(e) => setExNo(acceptOnlyNumbers(e?.target?.value))} name="exno" />
															{exNoValidator.current.message('ExNo', exno, 'required')}                                           
														</div>
													</div>}
													{detailsType === 'orderno' && <div className="col-lg-6 col-12">
														<div className={`form-group -animated ${step2 ? 'select-disabled -active' : ''}`}>  
															<label>Enter Order No.<span className="mandatory">*</span></label>
															<input className="form-control" type="text" value={orderNo} onChange={(e) => setOrderNo(acceptOnlyNumbers(e?.target?.value))} name="orderno" />
															{orderNoValidator.current.message('OrderNo', orderNo, 'required')}                                           
														</div>
													</div>}
													{step2 === true && <div className="col-lg-6 col-md-12">
														<div className={`form-group -animated -focus -active ${step2 === true ? 'select-disabled' : ''}`}>
															<label>Mobile No<span className="mandatory">*</span></label>
															<input className="form-control" type="text" disabled={step2 === true ? true : false} value={step2 === true ? mobile.replace(/^.{7}/g, '*******') : mobile} name="mobile" />
														</div>
													</div>}
												</div>
												{step2 === true && <div class="row">
													<div className="col-lg-12 col-md-12">
														<div className="row otp_row">
															<div className="col-lg-6 col-md-12">
																<div className="form-group -animated">
																	<label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
																	<input onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))} className="form-control" maxlength="6" type="text" value={otp} />
																	{/* <i className="icon success"></i> */}
																	<span className="err_msg">Please enter OTP shared on your mobile number.</span>
																	{otpValidator.current.message('otp', otp, 'required')}
																	{/* <span className="field_note">If OTP not recieved send via SMS or WhatsApp.</span>  */}
																</div>
																<LinkButton title={`Resend OTP`} resend onBtnClick={() => onResendOTP()} loading={otpLoading} />
															</div>
														</div>
													</div>
												</div>}
												<div class="row">
													<div class="col-lg-5 col-12 py-3 mb-4 d-flex">
														{detailsType === 'hh_no' 
														? step2 === true 
															? <>
																<APIButton title={`Back`} 
																	onBtnClick={(e) => handleMobileBack(e)}  
																	className={`btn btn-primary mr-3 btn-primary-solid back_btn`} 
																/>
																<APIButton title={`Submit`} 
																	loading={btnLoading} 
																	disabled={btnLoading} 
																	onBtnClick={(e) => handleMobileNoSubmit(e)}  
																	className={`btn btn-primary`} 
																/> 
															</>
															: <APIButton title={`Submit`} 
																loading={btnLoading} 
																disabled={btnLoading} 
																onBtnClick={(e) => handleHHSubmit(e)}  
																className={`btn btn-primary`} 
															/> 
														: null}
														{detailsType === 'exno' 
														? step2 === true 
															? <>
																<APIButton title={`Back`} 
																	onBtnClick={(e) => handleMobileBack(e)}  
																	className={`btn btn-primary mr-3 btn-primary-solid back_btn`} 
																/>
																<APIButton title={`Submit`} 
																	loading={btnLoading} 
																	disabled={btnLoading} 
																	onBtnClick={(e) => handleMobileNoSubmit(e)}  
																	className={`btn btn-primary`} 
																/> 
															</>
															: <APIButton title={`Submit Exno`} 
																loading={btnLoading} 
																disabled={btnLoading} 
																onBtnClick={(e) => handleEXNoSubmit(e)}  
																className={`btn btn-primary`} 
															/>
														: null}
														{detailsType === 'orderno' 
														? step2 === true 
															? <>
																<APIButton title={`Back`} 
																	onBtnClick={(e) => handleMobileBack(e)}  
																	className={`btn btn-primary mr-3 btn-primary-solid back_btn`} 
																/>
																<APIButton title={`Submit`} 
																	loading={btnLoading} 
																	disabled={btnLoading} 
																	onBtnClick={(e) => handleMobileNoSubmit(e)}  
																	className={`btn btn-primary`} 
																/> 
															</>
															: <APIButton title={`Submit Order`} 
																loading={btnLoading} 
																disabled={btnLoading} 
																onBtnClick={(e) => handleOrderNoSubmit(e)}  
																className={`btn btn-primary`} 
															/>
														: null}
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<p>* Reports are available for 30 days only</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="blueBoxWrap">
										<h5>Please follow below steps to View/Print your Report Online:</h5>
										<ol class="number_list pl-0">
											<li>Enter HH No. / EX No. or Order No.</li>
											<li>Click <b>"SUBMIT"</b></li>
											<li>Validate last 4 digits of your Mobile Number by clicking <b>"CONFIRM"</b>.</li>
											<li>You will now receive One Time Password (OTP) on your registered Mobile Number</li>
											<li>Enter OTP and Click <b>"SUBMIT"</b>. You can re-generate OTP, in case you have not received in a minute.</li>
										</ol>
									</div>	
								</div>	
								<div class="online-reports-details">
									<div class="row">
										{/* <div class="col-12">
											<h5 class="pb-2">Please follow below steps to View/Print your Report Online:</h5>
											<ol class="number_list pl-0">
												<li>Enter HH No. / EX No. or Order No.</li>
												<li>Click <b>"SUBMIT"</b></li>
												<li>Validate last 4 digits of your Mobile Number by clicking <b>"CONFIRM"</b>.</li>
												<li>You will now receive One Time Password (OTP) on your registered Mobile Number</li>
												<li>Enter OTP and Click <b>"SUBMIT"</b>. You can re-generate OTP, in case you have not received in a minute.</li>
											</ol>
										</div> */}
										<div class="col-12">
											<h5>The online report system is currently available for the following sections:</h5>
											<ul class="bullet_list">
												<li>CT SCAN</li>
												<li>DSA</li>
												<li>GENERAL RADIOLOGY</li>
												<li>IMAGING</li>
												<li>MAMMOGRAPHY</li>
												<li>MRI</li>
												<li>PLAIN X RAY</li>
												<li>LAB MEDICINE</li>
												<li>NUCLEAR MEDICINE &amp; PET- CT</li>
												<li>ULTRASOUND</li>
												<li>CARDIOLOGY - ECHO, STRESS TEST</li>
												<li>PFT</li>
												<li>NEUROLOGY - EMG, EEG</li>
												<li>URODYNAMICS</li>
											</ul>
										</div>
										<div class="col-12">
											<h5>Disclaimer:</h5>
											<ol class="number_list pl-0">
												<li>As the report is confidential it cannot be emailed and therefore you are requested to kindly pick It up from the hospital counter. Molecular biochemistry reports, Toxi screen reports and Amino Acid reports will be available at the Stat Lab counter.</li>
												<li>Please note that confidential and sensitive LAB REPORTS will not be available for viewing.</li>
												<li>For Graphs / DVDs / CDs of procedure, visit Hospital Report Delivery counter in OPD ground floor
													<br /> (Monday to Saturday 8 AM to 8 PM)</li>
											</ol>
											<h5>Please note -</h5>
											<ol class="number_list pl-0">
												<li>Site best viewed in IE8+, Firefox 3.5+, Chrome 3+, Safari 5.0+ at 1024 x 768 pixels resolution.</li>
												<li>You must have a current PDF reader to view Reports. If you do not see the Reports, please <a href="https://get.adobe.com/reader/" target="_blank">click here</a> to download PDF reader software.</li>
												<li>Please check you junk folder or spam folder in case you do not receive an email in your mail box within 10 mins.</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>}
			</main>
		</Layout>
	</>
	)
}

export const query = graphql`
query medicalReportQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/medical-report$/"}}}) {
		edges {
		  node {
			id
			title
			path {
			  alias
			}
			metatag {
				attributes {
					content
					href
					name
					property
					rel
				}
			}
		  }
		}
	}
  }  
`

export default MedicalReports